<template>
  <div id="edit_password">
    <div class="admin_main_block">
      <div class="user_temp_title">修改密码</div>
      <div class="admin_table_main">
        <div class="edit_password_from">
          <div class="item">
            <input
              type="password"
              placeholder="原密码"
              v-model="formData.oldPassword"
            />
          </div>
          <div class="item">
            <input
              type="password"
              placeholder="新密码"
              v-model="formData.newPassword"
            />
          </div>
          <div class="item">
            <input
              type="password"
              placeholder="确认密码"
              v-model="formData.confirmPassword"
            />
          </div>
          <div class="btn" @click="updatePassword">确定修改</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import rsa from "@/utils/rsa";

export default {
  name: "",
  data() {
    return {
      loading: false,
      formData: {
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",


      },

    };
  },
  created() {

  },
  mounted() {},
  watch: {},
  methods: {
    updatePassword() {
      if (this.loading) return;
      this.loading = true;
      const loading = this.$loading();
      if (!this.formData.oldPassword) {
        this.$message.error("旧密码不能为空");
        this.loading = false;
        loading.close();
        return;
      }
      if (!this.formData.newPassword) {
        this.$message.error("新密码不能为空");
        this.loading = false;
        loading.close();
        return;
      }
      if (!this.formData.confirmPassword) {
        this.$message.error("确认密码不能为空");
        this.loading = false;
        loading.close();
        return;
      }






      if (this.formData.newPassword != this.formData.confirmPassword) {
        this.$message.error("新密码与确认密码不一致");
        this.loading = false;
        loading.close();
        return;
      }
      this.$request.post({
        url: "/ReportApi/business/user/updatePassword",
        params: {
          id: localStorage.getItem("ids"),
          beforePassword: this.formData.oldPassword,
          password: this.formData.newPassword,



        },
        success: (res) => {
          this.$message.success("修改成功");
          this.$store.commit("getUserInfo", {});
          this.$store.commit("setIsLogin", false);
          this.$router.push("/login");
          localStorage.removeItem("dftToken");
          localStorage.removeItem("phone");
        },
        finally: () => {
          this.loading = false;
          loading.close();
        },
      });
    },









  },
};
</script>

<style lang="scss" scoped>
#edit_password {
  .admin_main_block {
    border-radius: 5px;
    min-height: 855px;
    background: #fff;
    width: 100%;
    box-sizing: border-box;
    padding: 20px;
    .user_temp_title {
      margin: auto;
      padding-left: 20px;
      border-bottom: 1px solid #eeeeee;
      padding-bottom: 20px;
      height: 25px;
      margin-bottom: 20px;
      font-weight: bold;
    }
    .admin_table_main {
      .edit_password_from {
        margin-top: 40px;
        padding-bottom: 30px;
        padding-left: 70px;
        .item {
          position: relative;
          width: 272px;
          line-height: 40px;
          margin-bottom: 30px;
          .imgCodeMode {
            position: absolute;
            top: 0;
            right: 0;
            height: 40px;
            cursor: pointer;
            img {
              height: 100%;
            }
          }
        }
        input {
          width: 272px;
          height: 40px;
          padding: 0 10px;
          border: 1px solid #ddd;
          border-radius: 6px;
          box-sizing: border-box;
          outline: none;
        }
        .btn {
          width: 270px;
          border: 0px;
          background-color: #ea242f;
          text-align: center;
          line-height: 40px;
          border-radius: 5px;
          color: #ffffff;
          font-size: 14px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
